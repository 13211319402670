<template>
  <div class="practical-training">
<!--    <div class="practical-training-header">-->
<!--      课程-->
<!--      <el-cascader :props="cascaderProp"  @change="selectCourse" style="width: 200px;margin-left: 12px" v-model="courseValue" :options="courseList"></el-cascader>-->
<!--    </div>-->
    <el-scrollbar class="main-left" :native="false">
      <div class="left-box">
        <div class="left-item" @click="selectCourse(0)"><span :class="courseValue ==  0? 'span-active text-overflow' : 'text-overflow'">全部课程</span></div>
        <div class="left-item" @click="selectCourse(item.course_id)" v-for="item in courseList"><span :class="item.course_id === courseValue ? 'span-active text-overflow' : 'text-overflow'">{{item.course_name}}</span></div>
      </div>
    </el-scrollbar>
    <div class="main-tight">
      <el-table class="customTable" :data="practicalList" style="width: 100%; margin-top: 20px; flex: 1;" height="1%" size="medium"
                :header-cell-style="{fontWeight: 'normal', height: '60px', color: '#666666', background: '#F6F6F6',fontSize: '16px'}"
                :cell-style="{fontSize: '16px',color: '#333333',height: '70px'}">
        <el-table-column prop="op_name" align="left" label="试题名称"></el-table-column>
        <el-table-column prop="assess_time" align="center" label="考核时间">
          <template slot-scope="scope">
            <span>{{scope.row.assess_time}}分钟</span>
          </template>
        </el-table-column>
        <el-table-column prop="drill_num" align="center" label="训练次数">
          <template slot-scope="scope">
            <span>{{scope.row.drill_num}}次</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-link type="primary" @click="toLookDetail(scope.row.id)" :underline="false">查看详情</el-link>
            <el-link type="primary" @click="toAnswerDetail(scope.row.id,scope.row.exam_module_id,scope.row)" :underline="false">答题详情</el-link>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination class="pager-center" style="margin: 20px;text-align: center"
                     :current-page="listPages.currentPageNum"
                     :page-size="listPages.eachPageNum"
                     :total="listPages.total"
                     layout="prev, pager, next, jumper"
                     @current-change="listCurrentChange">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import {getTeaSchoolCourse, examTeaOperationList} from '@/utils/apis'
export default {
  name: "PracticalTraining",
  data(){
    return {
      courseValue:0,
      courseList:[],
      practicalList:[],
      listPages:{
        currentPageNum: 1,
        eachPageNum: 10,
        total: 0,
      },
      cascaderProp:{value:'id',label:'name',children:'child'},
    }
  },
  mounted() {
    this.getCourseList();
  },
  methods:{
    // 获取课程
    getCourseList(){
      getTeaSchoolCourse({}).then((res)=>{
        // if(res.data.length){
        //   let list = [];
        //   res.data.forEach(item=>{
        //     let obj = {
        //       id:item.course_id,
        //       name:item.course_name,
        //       child:item.data
        //     }
        //     list.push(obj)
        //   })
        //   this.course_id = list[0].id;
        //   this.chapter_index = list[0].child[0].id;
        //   this.node_index = list[0].child[0].child[0].id;
        //   this.courseValue = [this.course_id,this.chapter_index,this.node_index]
        //   this.courseList = list;
        //   this.getTrainingList()
        // } else {
        //   this.courseList = []
        // }
        this.courseList = res.data;
        this.getTrainingList()
      }).catch((err)=>{
        console.log('err', err)
      })
    },
    // 获取训练列表
    getTrainingList(){
      let params = {
        page:this.listPages.currentPageNum,
        paging:1,
        pageSize:this.listPages.eachPageNum
      }
      if(this.courseValue){
        params.course_id = this.courseValue
      }
      examTeaOperationList(params).then((res)=>{
        this.practicalList = res.data.data;
        this.listPages.total = res.data.total;
      }).catch((err)=>{
        console.log('err', err)
      })
    },
    // 筛选课程
    selectCourse(id){
      this.courseValue = id;
      this.listPages.currentPageNum = 1
      this.getTrainingList()
    },
    // 查看详情
    toLookDetail(id){
      this.$router.push({
        path:'/trainingCenter/practicalTraining/lookDetail',
        query:{
          id:id
        }
      })
    },
    // 查看答题详情
    toAnswerDetail(id,moduleId,row){
      if(moduleId == 42){
        this.$router.push({
          path:'/trainingCenter/practicalTraining/answerDetailLevelThree',
          query:{
            id:id,
            moduleId:moduleId,
            course_id:row.course_id,
            chapter:row.chapter_id,
            node:row.node_id,
          }
        })
      } else {
        this.$router.push({
          path:'/trainingCenter/practicalTraining/answerDetail',
          query:{
            id:id,
            moduleId:moduleId
          }
        })
      }
    },
    listCurrentChange(val){
      this.listPages.currentPageNum = val;
      this.getTrainingList()
    }
  }
}
</script>

<style scoped lang="scss">
.practical-training{
  padding: 0px 20px;
  box-sizing: border-box;
  height: 100%;
  display: flex;
  .main-left{
    z-index: 98;
    //background: #F6F7FC;
    position: relative;
    top: -20px;
    height: calc(100% + 20px);
    border-right: 20px solid #F6F7FC;
    ::v-deep .el-scrollbar__wrap{
      overflow-x: hidden;
    }
    .left-box{
      width: 210px;
      display: flex;
      flex-direction: column;
      .left-item{
        width: 100%;
        cursor: pointer;
        line-height: 1;
        margin: 16px 0;
        display: flex;
        span{
          line-height: 1;
          color: #666;
          font-size: 14px;
        }
        .span-active{
          color: #1AB163;
        }
      }
    }
  }
  .main-tight{
    flex: 1;
    width: 1%;
    display: flex;
    flex-direction: column;
    padding: 0 20px;
  }
}
</style>